import React, { Fragment } from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const Contact = ({ location }) => {
    const headerChildren = <Fragment>
        <h1>Let's Chat</h1>
        <div className="header-meta text-secondary mt-2">
            <div>Feel free to shoot me message</div>
            <div>I'd love to have a chat</div>
        </div>
    </Fragment>;

    return (
        <Layout
            headerChildren={headerChildren}
            path={location.pathname}
        >
            <SEO
                title="Contact"
                pathname="/contact"
                description="Have a question? Want to collaborate? I don't care, just reach out!"
            />
        </Layout>
    );
};

export default Contact;